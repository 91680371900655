
import { createTheme } from "@material-ui/core";

const theme = createTheme({
    color:{
        primary:'#1976d2',
        secondary: '#F6D860',
        error:'#F05454'
    }
});

export default theme;