
import { withStyles } from '@material-ui/styles';
import styles from './styles';
import About from '../../components/Dashboard/content/Home/About';
import Item from '../../components/Dashboard/content/Home/Item';
import DropFisrt from '../../components/Dashboard/content/Home/DropFisrt';
import Roadmap from '../../components/Dashboard/content/Home/Roadmap';
import More from '../../components/Dashboard/content/Home/More';

const Home = (props) => {
    const { classes,data } = props;
    return (
        <div className={classes.home}>
            <About data={data.find(a => a.id === 1)} />
            <Item data={data.filter(a => a.id >= 2 && a.id <= 5)} />
            {/* <More /> */}
            <DropFisrt data={data.filter(a => a.id >= 12 && a.id <= 15)} />
            <Roadmap />
        </div>
    )
}

export default withStyles(styles)(Home);