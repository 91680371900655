

import main_banner from './../../assets/imgs/MainBanner.jpg'
const obj = window.innerWidth > 600? {
    on_roll:{
        top:'34.5%',
        left:'42.2%',
        width:'13rem',
        height:'16rem'
    }
}:{
    on_roll:{
        top:'46%',
        left:'40.1%',
        width:'3.5rem',
        height:'4.5rem',     
    }
}
const styles = (theme) => ({
    ...obj,
    mainBanner:{
        // background: `url(${main_banner}) no-repeat top center`,
        // backgroundSize:'100% auto',
        // "@media (max-width: 768px)":{
        //     backgroundSize:'auto'
        // },
        // width:'100%',
        // height:'100vh',
        // maxWidth:1920,
        // maxHeight:1080,
        position:'relative',
        fontSize: "0px !important"
    },
    onmain_banner:{
        width:'100%',
        overflow:'hidden',
        position:'relative'
    },
    body:{
        
    },
    img_main_banner:{
        position:'relative',
        width:'100%',
        display:'block',
        minWidth:400,
        marginLeft:'auto',
        marginRight:'auto'
    },
    roll:{
        position:'absolute',
        zIndex:1,
    }
});

export default styles;