import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import { ROUTE_ADMIN } from "./../../constants";
import Header from "./../../components/Dashboard/Header";
import styles from "./styles";
import Footer from "../../containers/Footer";
import main_banner from './../../assets/imgs/MainBanner.jpg'
import main_banner_m from './../../assets/imgs/MainBanner_m.jpg'
import roll from './../../assets/imgs/roll.gif'
import * as constants from './../../constants';
import * as siteActions from './../../actions/site';
import { useEffect } from "react";
import cn from "classnames";

//import PropTypes from 'prop-types'

const Dashboard = (props) => {
  const { classes, siteActionsCreator, site } = props;
  const { fetchDataRequest } = siteActionsCreator;

  useEffect(() => {
    if (site.data.length === 0) {
      fetchDataRequest();
    }
  }, []);

  function renderRoute() {
    var xhtml = null;
    xhtml = ROUTE_ADMIN.map((item) => {
      return (
        <Route key={item.path} path={item.path} element={<item.component data={site.data} />} />
      );
    });
    return xhtml;
  }

  return (
    <div className={classes.mainBanner}>
      <div className={classes.onmain_banner}>
        <img src={window.innerWidth > 600 ? main_banner : main_banner_m}
          className={classes.img_main_banner}
          alt={constants.alt}
        />

        <img src={window.innerWidth > 600 ? roll : roll}
          className={cn(classes.roll,classes.on_roll)}
          alt={constants.alt}
        />

        <Header />
      </div>
      <div className={classes.body} id='fiw'>
        <Routes>{renderRoute()}</Routes>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    siteActionsCreator: bindActionCreators(siteActions, dispatch)
  };
};

var withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withStyles(styles))(Dashboard);
