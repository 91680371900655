import * as ui_constants from './../constants/ui';
var initial = {
    isShowSlideBar: true
}

const reducer = (state = initial, action) => {
    switch (action.type) {
        case ui_constants.SHOW_SLIDEBAR:
            return { ...state, isShowSlideBar: action.status };
        default:
            return state;
    }
}

export default reducer;