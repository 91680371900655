import styles from "./styles";
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import item from './../../../../../assets/imgs/nftitem.jpg';
import item_m from './../../../../../assets/imgs/nftitem_m.jpg';
import * as constants from "./../../../../../constants";
import rod from './../../../../../assets/imgs/rod.png';
import bait from './../../../../../assets/imgs/bait.png';
import fakebait from './../../../../../assets/imgs/fakebait.png';
import land from './../../../../../assets/imgs/land.png';
import 'react-perfect-scrollbar/dist/css/styles.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import parse from 'html-react-parser';

const Item = (props) => {
  const { classes,data } = props;
  const [st,setSt] = useState(null);

  useEffect(() => {
    setSt(data);
  },[data]);
  
  const showContent = (id) => {
    var rod = "";
    var chk = data?.find(a => a.id === id);
    if(chk){
      rod = chk.content;
    }
    return(
      parse(rod)
    )
  }
  
  return (
    <div id="nftitem" className={classes.nftitem}>
      <img
        src={window.innerWidth > 600 ? item : item_m}
        className={classes.img}
        alt={constants.alt}
        title={constants.title}
      />
      <div className={classes.list}>
        <OwlCarousel className='owl-theme'  margin={10} autoplay={true}  items={window.innerWidth > 600 ?2:1} nav>
          <div className="item">
            <div className={classes.boxItem}>
              <div className={classes.myitem}>
                <img src={rod} alt={constants.alt} />
                <div  className={classes.content}>
                  {showContent(2)}
                </div>
              </div>
              <div className={classes.myitem}>
                <img src={bait} alt={constants.alt} />
                <div className={classes.content}>
                {showContent(3)}
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className={classes.boxItem}>
              <div className={classes.myitem}>
                <img src={fakebait} alt={constants.alt} />
                <div className={classes.content}>
                {showContent(4)}
                </div>
              </div>
              <div className={classes.myitem}>
                <img src={land} alt={constants.alt} />
                <div className={classes.content}>
                {showContent(5)}
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>

        
      </div>
    </div>
  );
};

export default withStyles(styles)(Item);
