
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './../reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './../sagas';

const composeEnhancer = process.env.NODE_ENV !== "production" &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        shouldHotReload: false,
    }) : compose;

const sagaMiddleware = createSagaMiddleware();

const configureStore = () => {
    const middleWare = [thunk, sagaMiddleware];
    const enhancers = [applyMiddleware(...middleWare)];
    const store = createStore(
        rootReducer,
        composeEnhancer(...enhancers)
    );
    sagaMiddleware.run(rootSaga);
    return store
}

export default configureStore;