import styles from "./styles";
import React from "react";
import { withStyles } from "@material-ui/core";
import bg from './../../../../../assets/imgs/roadmap.jpg';
import bg_m from './../../../../../assets/imgs/roadmap_m.jpg';
import * as constants from "./../../../../../constants";

const Roadmap = (props) => {
  const { classes } = props;
  return (
    <div id="roadmap" className={classes.roadmap}>
      <img
        src={window.innerWidth > 600?bg:bg_m}
        className={classes.img}
        alt={constants.alt}
        title={constants.title}
      />
    </div>
  );
};

export default withStyles(styles)(Roadmap);
