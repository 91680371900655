import axios from "axios";

class AxiosServices {
    constructor(){
        const instance = axios.create();
        instance.interceptors.response.use(this.handleSuccess,this.handleFailed);
        this.instance = instance;
    }
    handleSuccess(response){
        return response
    }

    handleFailed(error){
        return Promise.reject(error);
    }

    get(url){
        return this.instance.get(url);
    }

    post(url,data,opt){
        return this.instance.post(url,data,opt);
    }


}

export default new AxiosServices();