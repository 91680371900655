
const styles = (theme) => ({
    img:{
        width:'100%',
        display:'block',
    },
    roadmap:{
        position:'relative',
        textAlign:'center',
    }
});

export default styles;