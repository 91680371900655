
import styles from './styles';
import React from 'react';
import { withStyles } from '@material-ui/core';
import * as constants from './../../constants';
import sec1 from './../../assets/imgs/sec1.jpg';
import sec3 from './../../assets/imgs/sec3.jpg';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';

const Infomation = (props) => {
    const { classes, data } = props;
    let { id } = useParams();
    var txt = "";
    var content = "";
    switch (id.toLowerCase()) {
        case "our_story":
            txt = "Our Story";
            content = data?.find(a => a.id === 6)?.content;
            break;
        case "terms":
            txt = "Terms";
            content = data?.find(a => a.id === 8)?.content;
            break;
        case "tokens":
            txt = "FSF Token";
            content = data?.find(a => a.id === 9)?.content;
            break;
        case "support":
            txt = "Game Support";
            content = data?.find(a => a.id === 11)?.content;
            break;
        case "feedback":
            txt = "Game FeebBack";
            content = data?.find(a => a.id === 10)?.content;
            break;
        case "whitepaper":
            txt = "WhitePaper";
            content = data?.find(a => a.id === 16)?.content;
            break;
        default:
            txt = "Getting Started";
            content = data?.find(a => a.id === 7)?.content;
            break;
    }
    content = content ? content : "";
    console.log("content:", content);
    return (
        <div className='box'>
            <div className='box1'>
                <img className='img' src={sec1} alt={constants.alt} />
                <span className='title'>{txt}</span>
            </div>
            <div className='box2'>
                <div className='on-box2'>
                    {parse(content)}
                </div>
            </div>
            <div className='box3'>
                <img className='img' src={sec3} alt={constants.alt} />
            </div>
        </div>
    )
}


export default withStyles(styles)(Infomation)