import Dashboard from "../containers/Dashboard";
import Infomations from "../containers/Infomations";
import Home from "../containers/Home";

export const alt = 'fishingworld.io';
export const title = 'Play to earn with fishingworld.io';


export const ROUTE_ADMIN_LAYOUT = {
    name: "Layout quản trị",
    path: "*",
    component: Dashboard
};
export const ROUTE_ADMIN = [{
    name: "FishingWorld - NFT Game play to earn",
    path: "/",
    component: Home
}, {
    name: "FishingWorld - Our Story",
    path: "/infomations/:id",
    component: Infomations
}
];
