import * as types from './../constants/site';
var initial = {    
    data: []
}

const reducer = (state = initial, action) => {
    switch (action.type) {
        case types.FETCH_DATA_FAILED:
            const { error } = action.payload;
            console.log("FETCH_DATA_FAILED:", error);
            return { ...state };
        case types.FETCH_DATA_SUCCESS:
            const {data} = action.payload;
            state.data = data;
            return { ...state };
        default:
            return state;
    }
}

export default reducer;