
const styles = (theme) => ({
    home:{
        margin:0,
        padding:0,
        top:-6,
        position:'relative',
    },
    div1:{
        border:'2px solid red',
        width:200,
        height:200
    },
    div2:{
        border:'2px solid green',
        width:200,
        height:200
    }
});

export default styles;