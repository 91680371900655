import headbar from "./../../../assets/imgs/headbar.png";
import headbar_m from "./../../../assets/imgs/headbar_m.png";
import wood_board from "./../../../assets/imgs/wood_board.png";
import our_story from "./../../../assets/imgs/our_story.png";
import our_story_active from "./../../../assets/imgs/our_story_active.png";
import get_start from "./../../../assets/imgs/get_start.png";
import get_start_active from "./../../../assets/imgs/get_start_active.png";
import whitepaper from "./../../../assets/imgs/whitepaper.png";
import whitepaper_active from "./../../../assets/imgs/whitepaper_active.png";
import tele from "./../../../assets/imgs/tele.png";
import twitter from "./../../../assets/imgs/twitter.png";
import discord from "./../../../assets/imgs/discord.png";
import fb from "./../../../assets/imgs/fb.png";
import playnow from "./../../../assets/imgs/playnow.png";
import btnbar from "./../../../assets/imgs/btnbar.png";
import home from "./../../../assets/imgs/home.png";
import home_active from "./../../../assets/imgs/home_active.png";

const styles = (theme) => (window.innerWidth>600?{
  row_absolute: {
    position: "absolute",
    top: 0,
  },
  Header:{
    background: `url(${headbar}) no-repeat top center`,
    backgroundSize: "100% auto",
    width: "85%",
    height: "9rem",
    position:'fixed',
    marginLeft:'7.5%',
    zIndex:9999
  },
  onHeader: {
    width: "88%",
    margin: "auto",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    "& a": {
      backgroundSize: "auto 4.5rem",
      marginLeft: "1.5rem",
      display:'inline-block',
      height:'100%',
    }
  },
  small_logo: {
    width: "18%",
    "& img": {
      width: "100%",
      cursor: "pointer",
    },
    position: "relative",
    top: "8%"
  },  
  main_menu:{
    width:'64%',
    display:'inline',
    zIndex:99999,
  },
  home: {
    width: "16%",
    background: `url(${home}) no-repeat center`,
  },
  home_active: {
    background: `url(${home_active}) no-repeat center`,
  },
  our_story: {
    width: "20%",
    background: `url(${our_story}) no-repeat center`,
  },
  our_story_active: {
    background: `url(${our_story_active}) no-repeat center`,
  },
  get_start: {
    width: "28%",
    background: `url(${get_start}) no-repeat center`,
  },
  get_start_active: {
    background: `url(${get_start_active}) no-repeat center`,
  },
  whitepaper: {
    width: "22%",
    background: `url(${whitepaper}) no-repeat center`,
    marginRight:'4rem'
  },
  whitepaper_active: {
    background: `url(${whitepaper_active}) no-repeat center`,
  },
  tele: {
    width: "4%",
    background: `url(${tele}) no-repeat center`,
  },
  twitter: {
    width: "4%",
    background: `url(${twitter}) no-repeat center`,
  },
  discord: {
    width: "4%",
    background: `url(${discord}) no-repeat center`,
  },
  fb: {
    width: "4%",
    background: `url(${fb}) no-repeat center`,
  },
  ml05rem:{
    marginLeft:'0.1rem !important'
  },
  playnow: {
    width: "15%",
    background: `url(${playnow}) no-repeat center`,
  },
  hidpc:{
    display:'none !important'
  }
}:
{
  row_absolute: {
    position: "absolute",
    top: 0,
  },
  Header:{
    background: `url(${headbar_m}) no-repeat top center`,
    backgroundSize: "100% auto",
    width: "100%",
    height: "6rem",
    position:'fixed',
    zIndex:9999
  },
  onHeader: {
    margin: "auto",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems:'center',
    justifyContent:'space-between',
    "& a": {
      backgroundSize: "auto 2.5rem",
      display:'inline-block',
      height:'100%',
    }
  },
  small_logo: {
    width: "40%",
    "& img": {
      width: "80%",
      cursor: "pointer",
    },
    position: "relative",
    display:'inline',
    textAlign:'center'
  },
  main_menu:{
    background: `url(${wood_board}) no-repeat center`,
    backgroundSize:'auto 100%',
    position:'absolute',
    width:'50%',
    display:'hidden',
    right:0,
    padding:"10px 0",
    borderRadius:5,
    top:"6rem",
    "& a": {
      backgroundSize: "auto 2.5rem",
      display:'block !important',
      height:'3rem !important',
      width:"100%"
    },
    transition: theme.transitions.create('right', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
  },
  shiftRight:{
    right:'-50%',
    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  home: {
    width: "10%",
    background: `url(${home}) no-repeat center`,
  },
  home_active: {
    background: `url(${home_active}) no-repeat center`,
  },
  our_story: {
    width: "10%",
    background: `url(${our_story}) no-repeat center`,
  },
  our_story_active: {
    width: "12%",
    background: `url(${our_story_active}) no-repeat center`,
  },
  get_start: {
    width: "18%",
    background: `url(${get_start}) no-repeat center`,
  },
  get_start_active: {
    background: `url(${get_start_active}) no-repeat center`,
  },
  whitepaper: {
    width: "13%",
    background: `url(${whitepaper}) no-repeat center`,
    marginRight:'4rem'
  },
  whitepaper_active: {
    width: "14%",
    background: `url(${whitepaper_active}) no-repeat center`,
  },
  tele: {
    width:"9%",
    background: `url(${tele}) no-repeat center`,
    display:'block',
  },
  twitter: {
    width: "9%",
    background: `url(${twitter}) no-repeat center`,
  },
  discord: {
    width: "9%",
    background: `url(${discord}) no-repeat center`,
  },
  fb: {
    width: "9%",
    background: `url(${fb}) no-repeat center`,
  },
  ml05rem:{
  },
  playnow: {
    background: `url(${playnow}) no-repeat center`,
    marginBottom:5
  },  
  btnbar: {
    width: "20%",
    background: `url(${btnbar}) no-repeat center`,
    backgroundSize:'auto 2rem !important'
  },
  hid:{
    display:'hidden'
  }
});

export default styles;
