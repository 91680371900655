import styles from "./styles";
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import drop_fisrt from './../../../../../assets/imgs/drop_fisrt.jpg';
import drop_fisrt_m from './../../../../../assets/imgs/drop_fisrt_m.jpg';
import * as constants from "./../../../../../constants";
import baitbag_pack from './../../../../../assets/imgs/baitbag_pack.png';
import fakebait_pack from './../../../../../assets/imgs/fakebait_pack.png';
import land_pack from './../../../../../assets/imgs/land_pack.png';
import rod_pack from './../../../../../assets/imgs/rod_pack.png';
import 'react-perfect-scrollbar/dist/css/styles.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import parse from 'html-react-parser';

const DropFirst = (props) => {
  const { classes,data } = props;
  const [st,setSt] = useState(null);

  useEffect(() => {
    setSt(data);
  },[data]);

  const showContent = (id) => {
    var rod = "";
    var chk = data?.find(a => a.id === id);
    if(chk){
      rod = chk.content;
    }
    return(
      parse(rod)
    )
  }
  return (
    <div id="dropfirst" className={classes.dropfirst}>
      <img
        src={window.innerWidth > 600 ? drop_fisrt : drop_fisrt_m}
        className={classes.img}
        alt={constants.alt}
        title={constants.title}
      />
      <div className={classes.list}>

        <OwlCarousel className='owl-theme'  margin={10} autoplay={true}  items={window.innerWidth > 600 ?2:1} nav>
          <div className="item">
            <div className={classes.boxItem}>
              <div className={classes.myitem}>
                <img src={land_pack} alt={constants.alt} />
                <div className={classes.content}>
                {showContent(12)}
                </div>
              </div>
              <div className={classes.myitem}>
                <img src={rod_pack} alt={constants.alt} />
                <div className={classes.content}>
                {showContent(13)}
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className={classes.boxItem}>
              <div className={classes.myitem}>
                <img src={fakebait_pack} alt={constants.alt} />
                <div className={classes.content}>
                {showContent(14)}
                </div>
              </div>
              <div className={classes.myitem}>
                <img src={baitbag_pack} alt={constants.alt} />
                <div className={classes.content}>
                {showContent(15)}
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>        
      </div>
    </div>
  );
};

export default withStyles(styles)(DropFirst);
