
const obj = window.innerWidth>600?{
    list: {
        width: '85%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: '7.5%',
        zIndex: 2,
        padding: '11.5% 18% 10% 18%',
    },
    boxItem:{
        display:'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    myitem: {
        "& img":{
            width:'50% !important',
        },
        height:'29rem',
        marginBottom:10,
        display:'flex',
        justifyContent: 'center',
    },
    content:{
        width:'50% !important',
        color:'#e7ddd3',
        fontSize:'2.1rem !important',
        paddingLeft:'8px',
        height:'100% !important',
        margin:0,
        overflow:'auto',
        wordWrap:'break-word'
    }
}:{
    list: {
        width: '96%',
        height: '80%',
        position: 'absolute',
        top: 0,
        left: '2%',
        zIndex: 2,
        padding: '21% 18% 10% 18%'
    },
    boxItem:{
        display:'flex',
        justifyContent: 'space-between',
    },
    myitem: {
        "& img":{
            height:'50% !important',
            width:'auto !important',
            margin:'auto'
        },
        height:'17.5rem',
        display:'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign:'center',
        width:'49%'
    },
    content:{
        width:'100% !important',
        color:'#e7ddd3',
        fontSize:'1rem !important',
        paddingLeft:'8px',
        height:'100% !important',
        margin:0,
        overflow:'auto',
        wordWrap:'break-word'
    }

}
const styles = (theme) => ({
    ...obj,
    nftitem: {
        position: 'relative',
    },
    img: {
        width: '100%',
        position: 'relative',
    },
    onList: {
        width: '100%',
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '49% 49%',
        gridAutoRows: '50%',
        gridGap: '2%'
    },
    item: {
        display:'flex',
        justifyContent:'center',
        "& img":{
            height:'100%'
        },
        overflow:'auto',
        wordWrap:'break-word',
    },
    content_bk:{
        padding:'0 8px'
    }
});

export default styles;