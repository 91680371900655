
import { withStyles } from '@material-ui/styles';
import * as React from 'react';
import styles from './styles';
import logo_footer from './../../assets/imgs/small_logo.png';
import * as constants from './../../constants/';
import { NavLink } from 'react-router-dom';
import tele from "./../../assets/imgs/tele.png";
import twitter from "./../../assets/imgs/twitter.png";
import discord from "./../../assets/imgs/discord.png";
import fb from "./../../assets/imgs/fb.png";
import subscribe from "./../../assets/imgs/subscribe.png";
import cn from 'classnames';
import * as utils from './../../commons/utils';
import { toast } from 'react-toastify';

const Footer = (props) => {

    const { classes } = props;

    const [dataForm, setDataForm] = React.useState({
        email: "",
    });
    var ipt_email = React.createRef();

    const goto = (id) => {
        var num = 0;
        var obj = document.getElementById(id);
        var divOffset = utils.offset(obj);
        if (id === "nftitem") {
            num = 60;
        }
        if (id === "roadmap") {
            num = 20;
        }
        window.scrollTo({ top: divOffset.top - num, behavior: 'smooth' });
    }
    const handleOnchange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.value;
        setDataForm({
            ...dataForm,
            [name]: value
        });
    }
    const playGame = () => {
        var email = dataForm.email;
        console.log("email:",email);
        if (!email || !/.+@.+\.[A-Za-z]+$/.test(email)) {
            toast.error("Please enter your email valid", { autoClose: 3000 });
            ipt_email.current.focus();
            return false;
        }
      toast.success("Thanks for using, have a nice time with FishingWorld!", { type: toast.TYPE.SUCCESS, autoClose: 3000 });
    }

    return (
        <div className={cn(classes.footer, classes.subfooter)}>
            <div className={cn(classes.onFooter,classes.subOnFooter)}>
                <div className={classes.inside_footer}>
                    <div className={cn(classes.item, classes.subitem)}>
                        <div className={classes.textCenter}>
                            <img className={classes.img} src={logo_footer} alt={constants.alt} />
                        </div>
                        <div className={classes.emucation}>
                            <NavLink to={"/Home"} ><img src={tele} alt={constants.alt} /></NavLink>
                            <NavLink to={"//twitter.com/fishingworld_io"} target="_blank"><img src={twitter} alt={constants.alt} /></NavLink>
                            <NavLink to={"//discord.gg/B5Gy4W4tUm"} target="_blank" ><img src={discord} alt={constants.alt} /></NavLink>
                            <NavLink to={"/Home"} ><img src={fb} alt={constants.alt} /></NavLink>
                        </div>
                        <div className={cn(classes.textCenter,classes.company)}>
                            JAPA TRADE COMPANY LIMITED
                            <div>Address: 960 La Thanh Street, Ngoc Khanh Ward, Ba Dinh District, Hanoi City, Vietnam</div>
                        </div>
                    </div>
                    <div className={cn(classes.item, classes.subitem)}>
                        <div className={classes.subitem2}>
                            <div className={classes.links}>
                                <NavLink to={"/#about"} onClick={() => goto("about")}>About the game</NavLink>
                                <NavLink to={"/#nftitem"} onClick={() => goto("nftitem")}>NFT Item</NavLink>
                                <NavLink to={"/#roadmap"} onClick={() => goto("roadmap")}>Roadmap</NavLink>
                                {/* <NavLink to={"/infomations/terms/#fiw"}>Terms & Privacy</NavLink> */}
                            </div>
                            <div className={classes.links}>
                                <NavLink to={"/infomations/whitepaper/#fiw"}>Whitepaper</NavLink>
                                <NavLink to={"/infomations/tokens/#fiw"} >FSF Token</NavLink>
                                {/* <NavLink to={"/infomations/feedback/#fiw"} >Feedback to us</NavLink> */}
                                <NavLink to={"/infomations/support/#fiw"} >Support</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className={cn(classes.item, classes.subitem)}>
                        <div className={classes.boxSubscribe}>
                            <img className={classes.subcribe} src={subscribe} alt={constants.alt} />
                            <input type="text" 
                            className={cn(classes.txtEmail,classes.onTxt)}
                            name="email"
                            ref={ipt_email}
                            onChange={handleOnchange}
                             placeholder="Your email addess" />
                        </div>
                        <div className={cn('textCenter', classes.bound_btnsubcribe)}>
                            <button className={classes.btnsubcribe} onClick={() => playGame()} ></button>
                        </div>

                    </div>
                </div>
                <div className={classes.bottom}>
                    <div className={classes.bottom_left}>
                        ©2022 JAPA TRADE CO, LTD - 0109468653, manager: ManhTri Cu, contact: tri.cm.japa@gmail.com - All rights reserved
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(Footer);