import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import cn from "classnames";
import * as constants from "./../../../constants";
import small_logo from "./../../../assets/imgs/small_logo.png";
import { NavLink } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as ui_actions from './../../../actions/ui';
import { toast } from 'react-toastify';

const Header = (props) => {
  const { classes,isShowSlideBar } = props;
  const {uiActionsCretors} = props;
  const {showSlideBar} = uiActionsCretors;

  const toggleMenu = (e) => {
    showSlideBar(!isShowSlideBar);
  }

  const playGame = () => {
    toast.warning("The game is under construction, will be released soon in the near future", { type: toast.TYPE.warning, autoClose: 3000 });
  }

  return (
    <React.Fragment>
      <div className={cn("row", classes.row_absolute)}>
        <div className={classes.Header}>
          <div className={classes.onHeader}>
            <div className={classes.small_logo}>
              <NavLink to="/">
                <img
                  src={small_logo}
                  alt={constants.alt}
                  title={constants.title}
                />
              </NavLink>
            </div>

            <div className={cn(classes.main_menu,{[classes.shiftRight]:isShowSlideBar})}>
              <NavLink to="#" onClick={()=>playGame()} className={cn(classes.playnow,classes.hidpc)}></NavLink>
              <NavLink className={({ isActive }) => isActive ? cn(classes.home, classes.home_active) : classes.home} to="/"></NavLink>

              <NavLink to="/infomations/our_story/#fiw"
                className={({ isActive }) => isActive ? cn(classes.our_story, classes.our_story_active) : classes.our_story}
              ></NavLink>
              <NavLink to="/infomations/get_start/#fiw"
                className={({ isActive }) => isActive ? cn(classes.get_start, classes.get_start_active) : classes.get_start}
              ></NavLink>
              <NavLink to={"/infomations/whitepaper/#fiw"}
                className={({ isActive }) => isActive ? cn(classes.whitepaper, classes.whitepaper_active) : classes.whitepaper}
              ></NavLink>
            </div>

            <NavLink to="/" target="_blank" className={cn(classes.tele, classes.ml05rem)}></NavLink>
            <NavLink to="//twitter.com/fishingworld_io" target="_blank" className={cn(classes.twitter, classes.ml05rem)}></NavLink>
            <NavLink to="//discord.gg/B5Gy4W4tUm" target="_blank" className={cn(classes.discord, classes.ml05rem)}></NavLink>
            <NavLink to="/" target="_blank" className={cn(classes.fb, classes.ml05rem)}></NavLink>
            <NavLink to="#" onClick={()=>playGame()} className={cn(classes.playnow,classes.hid)}></NavLink>
            <NavLink to="#" className={classes.btnbar} onClick={() => toggleMenu()}></NavLink>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
      isShowSlideBar: state.ui.isShowSlideBar
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      uiActionsCretors: bindActionCreators(ui_actions, dispatch)
  }
}

var withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  withStyles(styles)
)(Header);

