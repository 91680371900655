
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminLayoutRoute from '../../commons/layout/AdminLayoutRoute';
import theme from '../../commons/theme';
import { ROUTE_ADMIN_LAYOUT } from '../../constants';
import configureStore from '../../redux/configureStore';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import styles from './styles';

const store = configureStore();
var last_size = window.innerWidth;
function reportWindowSize() {
  if(last_size > 600 && window.innerWidth <= 600){
    window.location.reload();
  }
  else if(last_size <= 600 && window.innerWidth > 600){
    window.location.reload();
  }
  last_size = window.innerWidth;
}

window.onresize = reportWindowSize;

function App() {

  return (
    <Provider store={store}>
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route
              path={ROUTE_ADMIN_LAYOUT.path}
              element={<AdminLayoutRoute component={ROUTE_ADMIN_LAYOUT.component} />}
            />
          </Routes>
        </ThemeProvider>
      </Router>
      <ToastContainer pauseOnFocusLoss={false} limit={2} draggablePercent={60} />
    </Provider>
  );
}


export default withStyles(styles)(App);
