var obj = window.innerWidth > 600?
{
    content: {
        fontSize: '2.5rem',
        color: '#855933',
        position: 'absolute',
        top: '50%',
        left: '56%',
        width: '70rem',
        height: '35rem',
        transform: 'translate(-50%,-56%)',
        padding: '3rem 0',
        textAlign: 'center',
        wordWrap: 'break-word',
        lineHeight: '3.5rem',
        overflow:'auto',
    }
}:{
    content: {
        fontSize: '1.5rem',
        color: '#855933',
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '23rem',
        height: '20rem',
        transform: 'translate(-50%,-50%)',
        textAlign: 'center',
        wordWrap: 'break-word',
        lineHeight: '2rem',
        overflow:'auto',
    }
}
const styles = (theme) => ({
    ...obj,    
    img: {
        width: '100%',
        display:'block',
    },
    about: {
        position: 'relative',
        zIndex:1,
        top:6
    },
});

export default styles;