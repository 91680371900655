import bg from './../../assets/imgs/footer_bg.jpg';
import bg_m from './../../assets/imgs/footer_bg_m.png';
import btnsubscribe from "./../../assets/imgs/btnsubscribe.png";
import { padding } from '@mui/system';

const obj = window.innerWidth > 600 ? {
    subOnFooter:{
        height:'100%'
    },
    subfooter: {
        height: '40rem',
        fontSize: '2.5rem'
    },
    inside_footer: {
        width: '85%',
        margin: 'auto',
        position: 'relative',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    subitem:{        
        width: '25%',
        flexDirection: 'column'
    },
    item: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    bound_btnsubcribe: {
        width: '60%',
        height: '7rem',
    },
    bottom: {
        position: 'relative',
        color: '#fff4d2',
        width: '85%',
        margin: 'auto',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    bottom_left: {
        float: 'left',
        display: 'cell',
        verticalAlign: 'middle',
        position: 'relative',
        top: "-1.2rem",
        height: '5rem',
        lineHeight: '5rem'
    },
    onTxt:{
        bottom: '5rem',
        height: '4rem',
        width: '70%',
        fontSize:'2rem'
    },
} : {
    subOnFooter:{
    },
    subfooter: {
        height: '20rem',
        fontSize: '1.5rem'
    },
    inside_footer: {
        width: '85%',
        margin: 'auto',
        position: 'relative',
        height: '100%',
        display: 'grid',
        justifyContent: 'center',
    },
    item: {
        height: '100%',
        display: 'grid',
        gridTemplateColumns: "auto",
        margin:20,
    },
    subitem:{        
        width: '100%',
        display:'block',
        padding:20
    },
    bound_btnsubcribe: {
        width: '60%',
        height: '4rem',
        margin:'auto'
    },
    bottom: {
        position: 'relative',
        color: '#fff4d2',
        width: '100%',
        margin: 'auto',
        textAlign:'center',
        padding:10,
        backgroundColor: '#261200',
    },
    bottom_left: {
    },
    onTxt:{
        bottom: '3rem',
        height: '3rem',
        width: '70%',
        fontSize:'1.5rem'
    }
}

const styles = () => ({
    ...obj,
    footer: {
        width: '100%',
        backgroundColor: '#261200',
        position: 'relative',
        display: 'block',
        bottom: 16
    },
    onFooter: {
        width: '100%',
        background: `url(${window.innerWidth > 600 ? bg : bg_m}) no-repeat top center`,
        backgroundSize: "100% auto",
        position: 'relative',
    },
    item: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    subitem2:{
        display: 'grid',
        gridTemplateColumns: "auto auto",
        textAlign:'center',
        width:'100%'
    },
    img: {
        width: '80%',
        margin: 'auto'
    },
    textCenter: {
        textAlign: 'center'
    },
    emucation: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        "& > a img": {
            width: '80%'
        }
    },
    links: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        "& > a": {
            color: '#fff4d2',
            margin: 5,
            textDecoration: 'none'
        },
        "& > a:hover": {
            textDecoration: 'underline'
        },
    },
    boxSubscribe: {
        position: 'relative'
    },
    subcribe: {
        width: '100%'
    },
    btnsubcribe: {
        width: '100%',
        height: '100%',
        background: `url(${btnsubscribe}) no-repeat top center`,
        backgroundSize: "100% auto",
        border: 0,
    },
    txtEmail: {
        backgroundColor: '#8c6c4c',
        border: 0,
        borderRadius: 3,
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%,0)',
        padding: '0 10px',
        color: '#ccc',
        "&:-moz-placeholder": {
            color: '#ccc',
        },
        "&:-ms-input-placeholder": {
            color: '#ccc',
        },
        "&::-webkit-input-placeholder": {
            color: '#ccc',
        },
        "&:focus": {
            outline: 'none',
            border: '1px solid #333'
        }
    },
    bottom_right: {
        display: 'cell',
        verticalAlign: 'middle',
        float: 'right',
        width: '10%',
        textAlign: 'right',
        justifyContent: 'space-between',
        "& > a": {
            color: '#fff4d2',
            textDecoration: 'none'
        },
        "& > a:hover": {
            textDecoration: 'underline'
        }
    },
    company:{
        fontSize:'2rem',
        color:"#fff4d2"
    }
});

export default styles;