import { useEffect, useRef } from "react";

export const getUnixTime = () => {
    const dateTime = new Date().getTime();
    const timestamp = Math.floor(dateTime / 1000);
    return timestamp;
}
export const getUnixTimeFromUTCString = (str) => {
    const dateTime = new Date(str + "Z").getTime();
    const timestamp = Math.floor(dateTime / 1000);
    return timestamp;
}

export const displayTime = (seconds) => {
    const format = val => `0${Math.floor(val)}`.slice(-2);
    const hours = seconds / 3600;
    const minutes = (seconds % 3600) / 60;

    if (seconds > 0) {
        return [hours, minutes, seconds % 60].map(format).join(':');
    }
    return seconds;
}
export function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest function.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
export const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const check_reload_waxapi = (e) => {
    if (e.message.indexOf("Countdown has not completed yet") > -1
        || e.message.indexOf("ate too much") > -1
        || e.message.indexOf("to fetch") > -1
        || e.message.indexOf("Cannot read properties of undefined (reading 'transact')") > -1
        || e.message.toLowerCase().indexOf("can't be harvested yet") > -1
        || e.message.indexOf("Not enought time have passed since the last reward claim on this asset") > - 1
    ) {
        return true;

    }
    return false;
}

export function offset(el) {
    var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}