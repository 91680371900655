import styles from "./styles";
import React from "react";
import { withStyles } from "@material-ui/core";
import item from './../../../../../assets/imgs/nftitem.jpg';
import item_m from './../../../../../assets/imgs/nftitem_m.jpg';
import * as constants from "./../../../../../constants";
import rod from './../../../../../assets/imgs/rod.png';
import bait from './../../../../../assets/imgs/bait.png';
import fakebait from './../../../../../assets/imgs/fakebait.png';
import land from './../../../../../assets/imgs/land.png';
import 'react-perfect-scrollbar/dist/css/styles.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Item = (props) => {
  const { classes } = props;
  return (
    <div id="nftitem" className={classes.nftitem}>
      <img
        src={window.innerWidth > 600 ? item : item_m}
        className={classes.img}
        alt={constants.alt}
        title={constants.title}
      />
      <div className={classes.list}>

        <OwlCarousel className='owl-theme' loop margin={10} autoplay={true}  items={window.innerWidth > 600 ?2:1} nav>
          <div className="item">
            <div className={classes.boxItem}>
              <div className={classes.myitem}>
                <img src={rod} alt={constants.alt} />
                <p className={classes.content}>
                  The fishing rod is one of the 3 items in the fishing suit. The higher the fishing rod, the more fish you can catch.
                  After each fishing, the fishing rod will wear out and need repair if it is badly damaged.
                </p>
              </div>
              <div className={classes.myitem}>
                <img src={bait} alt={constants.alt} />
                <p className={classes.content}>
                  Real disposable bait is not nft.
                  Each time fishing costs one bait, this bait is obtained by buying on the FIW market token on the wallet.
                </p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className={classes.boxItem}>
              <div className={classes.myitem}>
                <img src={fakebait} alt={constants.alt} />
                <p className={classes.content}>
                  Fake bait is 1 nft, can be used to fish many times and has a cost.
                  The more you fish in the big river, the more you lose, and in return, you will catch more fish and bigger fish.
                </p>
              </div>
              <div className={classes.myitem}>
                <img src={land} alt={constants.alt} />
                <p className={classes.content}>
                  Land is one of the three nft in the fishing suit.
                  The larger the land, the more fish it will hold, and the better fishing rods and bait will be required.
                </p>
              </div>
            </div>
          </div>
        </OwlCarousel>

        {/* <div className={cn(classes.onList,classes.subOnList)}>
          <div className={classes.item}>
            <img src={rod} alt={constants.alt} />
            <p className={classes.content}>
              The fishing rod is one of the 3 items in the fishing suit. The higher the fishing rod, the more fish you can catch.
              After each fishing, the fishing rod will wear out and need repair if it is badly damaged.
            </p>
          </div>
          <div className={classes.item}>
            <img src={bait} alt={constants.alt} />
            <p className={classes.content}>
              Real disposable bait is not nft.
              Each time fishing costs one bait, this bait is obtained by buying on the FIW market token on the wallet.
            </p>
          </div>
          <div className={classes.item}>
            <img src={fakebait} alt={constants.alt} />
            <p className={classes.content}>
              Fake bait is 1 nft, can be used to fish many times and has a cost.
              The more you fish in the big river, the more you lose, and in return, you will catch more fish and bigger fish.
            </p>
          </div>
          <div className={classes.item}>
            <img src={land} alt={constants.alt} />
            <p className={classes.content}>
            Land is one of the three nft in the fishing suit. 
            The larger the land, the more fish it will hold, and the better fishing rods and bait will be required.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default withStyles(styles)(Item);
