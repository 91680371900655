import styles from "./styles";
import React from "react";
import { withStyles } from "@material-ui/core";
import about from "./../../../../../assets/imgs/about.jpg";
import about_m from "./../../../../../assets/imgs/about_m.jpg";
import * as constants from "./../../../../../constants";
import parse from 'html-react-parser';

const About = (props) => {
  const { classes,data } = props;
  return (
    <div id="about" className={classes.about}>
      <img
        src={window.innerWidth>600?about:about_m}
        className={classes.img}
        alt={constants.alt}
        title={constants.title}
      />
      <div className={classes.content}>
        {parse(data?.content?data.content:"")}
      </div>
    </div>
  );
};

export default withStyles(styles)(About);
