import * as constants from './../constants/site';
import * as api from './../apis/apiSite';

export const fetchData= () => {
    return {
        type: constants.FETCH_DATA
    }
}

export const fetchDataSuccess = (data) => {
    return {
        type: constants.FETCH_DATA_SUCCESS,
        payload:{
            data
        }
    }
}
export const fetchDataFailed = (error) => {
    return {
        type: constants.FETCH_DATA_FAILED,
        payload:{
            error
        }
    }
}

export const fetchDataRequest = () => {
    var endPoint = "/Data.json";
    return (dispatch) => {
        return api
            .getList(endPoint)
            .then((res) => {
                dispatch(fetchDataSuccess(res.data));
            })
            .catch(error => {
                console.log("error:",error);
                dispatch(fetchDataFailed(error));
            });
    };
}